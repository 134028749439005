// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* export const environment = {
  production: false,
  firebase: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: ""
  }
};
 */
export const environment = {
  production: false,
   firebaseConfig: {
    apiKey: "AIzaSyDKqsxsY2XORZd7uIrtERX1EcKJIR_Mmhc",
    authDomain: "provver-c2eb1.firebaseapp.com",
    databaseURL: "https://provver-c2eb1-default-rtdb.firebaseio.com",
    projectId: "provver-c2eb1",
    storageBucket: "provver-c2eb1.appspot.com",
    messagingSenderId: "708396807130",
    appId: "1:708396807130:web:664d28fe8ca49ce209c6e3",
    measurementId: "G-LLD97M08ZF"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
